@use '../maps/rs-chips' as *;
@use '../abstract/mixins' as rsMixins;

.rs-chip {
  @each $key, $value in $rs-chips {
    &--#{$key} {
      @include rsMixins.rs-chip($key);
    }
  }
}

rs-chip {
  @each $key, $value in $rs-chips {
    &[rs-chip--color='#{$key}'] {
      @include rsMixins.rs-chip($key);
    }
  }
}
