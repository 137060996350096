mat-dialog-container.mat-mdc-dialog-container {
  padding: 0;
  overflow: visible;
  position: relative;

  .mat-mdc-dialog-surface {
    padding: 33px 40px 40px 40px;
    --mdc-dialog-container-shape: var(--rs-ds--border-radius);
  }

  .mat-mdc-dialog-title {
    @include rs-px-to-rem(18, 28);
    color: var(rs-color--grey--700);
    @include rs-font--primary-regular;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    &::before {
      height: auto;
    }

    // Remove autofocus hover state (UX demand)
    .mat-mdc-icon-button {
      &:not(:hover).cdk-program-focused .mat-mdc-button-persistent-ripple::before {
        --mat-icon-button-focus-state-layer-opacity: 0;
      }

      &:hover.cdk-program-focused .mat-mdc-button-persistent-ripple::before {
        --mat-icon-button-focus-state-layer-opacity: 0.04;
      }
    }

    button[mat-dialog-close] {
      color: var(--rs-color--grey--700);
      position: absolute;
      right: -17px;
      top: -11px;
    }

    &.overlay-close-button {
      position: absolute;
      width: 100%;
      border-bottom: none;
    }
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 0;
    @include rs-px-to-rem(14, 19);
    @include rs-font--primary-light;

    > :last-child {
      margin-bottom: 16px;
    }
  }

  .mat-mdc-dialog-actions {
    min-height: auto;
    margin-top: 27px;
    padding: 0;
    justify-content: flex-end;

    &.center-action-buttons {
      justify-content: center;
    }

    .mat-button-base + .mat-button-base,
    .mat-mdc-button-base + .mat-mdc-button-base {
      margin-left: 16px;
    }
  }
}
