.rs-datepicker {
  &.mat-mdc-form-field:not(.mat-form-field-disabled) {
    cursor: pointer;

    label {
      cursor: pointer !important;
    }

    input {
      pointer-events: none;
    }
  }

  &[appearance='outline'] {
    .mat-mdc-form-field-flex {
      height: 100%;
    }
  }

  &:not([appearance='outline']) {
    transform: translateY(0);
    transition: 0.1s transform ease-out;

    &.mat-form-field-hide-placeholder {
      .mat-datepicker-toggle {
        transform: translateY(8px);
        transition: 0.2s transform ease-out;
        transition-delay: 0.2s;
      }
    }
  }

  .mat-mdc-form-field-icon-suffix {
    display: flex;
  }

  .mat-datepicker-toggle {
    .mat-mdc-icon-button {
      height: 30px;
      width: 30px;
      padding: 8px 0;

      svg {
        height: 18px;
        width: 18px;
        transform: translateY(-1px);
      }
    }
  }
}
