@use '../../maps/rs-chips' as *;

@mixin rs-chip($color-key) {
  @include rs-font--primary-regular;
  @include rs-px-to-rem(11, 14);
  border-radius: 3px;
  border: 1px solid var(map-get(map-get($rs-chips, #{$color-key}), border-color));
  display: inline-block;
  padding: 3px 8px;

  &[rs-chip-density='1'] {
    @include rs-px-to-rem(14, 24);
    padding: 2px 8px;
  }

  background-color: var(map-get(map-get($rs-chips, #{$color-key}), bg-color));
  color: var(map-get(map-get($rs-chips, #{$color-key}), color));
}
