@import '../components/angular-material/rs-mat-buttons/rs-mat-buttons.utils';

.introjs-tooltipReferenceLayer {
  * {
    font-family: 'Montserrat-light' !important;
    font-weight: 400;
  }

  .introjs-tooltip {
    display: flex !important;
    flex-direction: column;

    &.step-1 {
      .introjs-prevbutton {
        display: none;
      }
    }

    .introjs-tooltiptext {
      padding-top: 0;
      padding-bottom: 0;

      h2,
      .title {
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 15px;
      }

      * {
        color: var(--rs-ds--text-color);
      }
    }

    .introjs-bullets {
      order: 2;
    }

    .introjs-tooltipbuttons {
      border-top: none;
      align-self: center;
      order: 1;

      .introjs-button {
        border: none;
        text-shadow: none;
        color: var(--rs-color--white);
        padding: 8px 30px;

        &:focus {
          box-shadow: none;
        }

        &.introjs-nextbutton {
          background-color: var(--rs-ds--color--primary);
          border-radius: var(--rs-ds--border-radius);
          padding: 7px 16px;

          &:hover {
            background-color: var(--rs-ds--color--primary--hover);
          }
        }

        &.introjs-prevbutton {
          margin-right: 15px;
          color: var(--rs-ds--color--primary);
          background-color: transparent;
          border: 1px solid var(--rs-ds--color--primary);
          border-radius: var(--rs-ds--border-radius);
          padding: 7px 16px;

          &:not(.introjs-disabled) {
            &:hover {
              border: 1px solid var(--rs-ds--color--primary--hover);
              color: var(--rs-ds--color--primary--hover);
            }
          }
        }
      }
    }
  }
}
