.rs-animation {
  &--shine {
    position: relative;
    overflow: hidden;

    @extend .rs-animation;

    &:after {
      content: '';
      top: 0;
      opacity: 0.8;
      transform: translateX(-200px);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      animation: shine 2s;
      animation-direction: alternate;
      animation-iteration-count: 2;
      animation-delay: 1s;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    }

    @keyframes shine {
      0% {
        transform: translateX(-200%);
      }

      40% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(100%);
      }
    }
  }
}
