:root {
  --mdc-switch-selected-hover-handle-color: var(--rs-color--white);
  --mdc-switch-selected-focus-handle-color: var(--rs-color--white);

  --mdc-switch-selected-hover-track-color: var(--rs-ds--color--primary--hover);
  --mdc-switch-selected-focus-track-color: var(--rs-ds--color--primary--hover);

  --mdc-switch-unselected-hover-handle-color: var(--rs-color--grey--700);
  --mdc-switch-unselected-focus-handle-color: var(--rs-color--grey--700);
}

mat-slide-toggle.mat-mdc-slide-toggle {
  --mdc-form-field-label-text-line-height: 22px;

  .mdc-switch__ripple {
    display: none !important;
  }
}
