@use '../../maps/rs-chips' as *;

@mixin rs-mat-form-field-colorize($color-key) {
  --mdc-outlined-text-field-outline-color: var(#{map-get(map-get($rs-chips, #{$color-key}), border-color)});
  --mdc-outlined-text-field-focus-outline-color: var(#{map-get(map-get($rs-chips, #{$color-key}), color)});
  --mdc-outlined-text-field-hover-outline-color: var(#{map-get(map-get($rs-chips, #{$color-key}), color)});

  --rs-mat-form-field--bg-color: var(#{map-get(map-get($rs-chips, #{$color-key}), bg-color)});

  --rs-mat-form-field--color: var(#{map-get(map-get($rs-chips, #{$color-key}), color)});
  --mat-select-enabled-trigger-text-color: var(#{map-get(map-get($rs-chips, #{$color-key}), color)});
  --mat-select-enabled-arrow-color: var(#{map-get(map-get($rs-chips, #{$color-key}), color)});
  --mat-select-focused-arrow-color: var(#{map-get(map-get($rs-chips, #{$color-key}), color)});
}
