@property --rs-mat-table--header-cell--align {
  syntax: 'center | flex-start';
  inherits: true;
  initial-value: flex-start;
}

@property --rs-mat-table--header-cell--justify {
  syntax: 'center | flex-start';
  inherits: true;
  initial-value: flex-start;
}

@property --rs-mat-table--cell--align {
  syntax: 'center | flex-start';
  inherits: true;
  initial-value: flex-start;
}

@property --rs-mat-table--cell--justify {
  syntax: 'center | flex-start';
  inherits: true;
  initial-value: flex-start;
}

:root {
  --mat-table-header-headline-font: 'Montserrat';
  --mat-table-header-headline-weight: 400;
}

.mat-mdc-table:not(table) {
  border-radius: 3px;

  .mat-sort-header {
    &:not(.mat-sort-header-disabled) {
      .mat-sort-header-container {
        transform: translateX(-18px);
      }
    }

    .mat-sort-header-container {
      flex-direction: row-reverse;
    }
  }

  .mat-mdc-header-cell {
    border-bottom: none;
    justify-content: var(--rs-mat-table--header-cell--justify);
  }

  .mat-mdc-cell {
    border-bottom: none;
    align-items: var(--rs-mat-table--cell--align);
    justify-content: var(--rs-mat-table--cell--justify);
    word-break: break-word;
  }

  .mat-mdc-footer-row,
  .mat-mdc-header-row,
  .mat-mdc-row {
    outline: none;
    border-bottom: 1px solid var(--rs-ds--border-color);
  }

  .mat-mdc-row:last-child {
    border-bottom: none;
  }

  .mat-mdc-header-row {
    box-shadow:
      0 1px 11px 0 rgba(0, 0, 0, 0.1),
      0 2px 5px -3px rgba(0, 0, 0, 0.2),
      0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  .mat-sort-header-content {
    flex-direction: column;
    text-align: left;
    align-items: var(--rs-mat-table--header-cell--align);
  }
}

// Table density
.mat-mdc-table {
  &[rs-table-density='0'] {
    --mat-table-header-headline-size: clamp(10px, 1cqw, 12px);
    --mat-table-header-headline-line-height: 1.125rem;
    --mat-table-row-item-label-text-line-height: 1.125rem;
    --mat-table-row-item-label-text-size: clamp(10px, 1cqw, 12px);

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 8px;
      word-break: break-word;
    }

    .mat-sort-header {
      &:not(.mat-sort-header-disabled) .mat-sort-header-container {
        transform: none;
      }

      .mat-sort-header-container {
        flex-direction: row;
      }
    }

    .mdc-data-table__header-cell {
      text-wrap: balance;
    }
  }

  // Default
  &:not(table):not([rs-table-density]) {
    .mat-mdc-cell {
      padding: 20px 16px;
    }

    .mat-mdc-header-cell {
      padding: 8px 16px;
    }

    .mat-mdc-cell {
      .sub {
        color: var(--rs-ds--text-secondary);
        @include rs-px-to-rem(12, 16);
      }
    }
  }
}

// Add clickable rows hover state
.mat-mdc-table.rs-table--clickable-rows {
  mat-row,
  .mat-mdc-row {
    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      cursor: pointer;
    }
  }

  .mat-mdc-cell {
    background-color: transparent;
  }
}

// Responsiveness
.mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) {
  container-name: rs-table-container;
  container-type: inline-size;

  @container rs-table-container (width <=769px) {
    .mat-sort-header-button,
    .mat-mdc-header-cell,
    .mat-mdc-cell {
      padding: 0 1rem;
    }

    .mat-mdc-row {
      --mat-table-row-item-label-text-line-height: 1.375rem;
      --mat-table-row-item-label-text-size: 0.875rem;

      gap: 1rem;
      padding-block: 12px;

      .mat-mdc-cell {
        justify-content: left !important;
        flex-basis: auto !important;
        align-items: start !important;
        flex-direction: column;

        &[rs-table-responsive-header]::before {
          content: attr(rs-table-responsive-header);
          color: var(--rs-ds--text-secondary);
          line-height: 1rem;
          font-size: 0.75rem;
        }
      }
    }

    .mat-mdc-footer-row,
    .mat-mdc-header-row {
      padding: 0;
    }

    .mat-mdc-footer-row::after,
    .mat-mdc-header-row::after,
    .mat-mdc-row::after {
      min-height: 16px;
    }

    .mat-mdc-row:nth-child(odd) {
      background-color: var(--rs-color--grey--50);
    }

    .mat-header-row-mobile {
      width: 100%;
    }

    .mat-mdc-header-row {
      display: none;
    }

    .mat-mdc-row {
      align-items: start;
      flex-direction: column;
    }
  }
}

// Using table with angular material cdk
table.mat-mdc-table {
  overflow: hidden !important;

  > * {
    word-wrap: break-word;
  }

  thead {
    box-shadow:
      0 1px 11px 0 rgba(0, 0, 0, 0.1),
      0 2px 5px -3px rgba(0, 0, 0, 0.2),
      0 -5px 0px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;

    tr {
      th {
        border: none;
        align-items: center;
        padding-left: 0;

        &:first-child {
          padding-left: 15px;

          &:not(.mat-sort-header:not(.mat-sort-header-disabled)) {
            padding-left: 30px;
          }
        }

        &:last-child {
          padding-right: 15px;
        }

        &:not(.mat-sort-header:not(.mat-sort-header-disabled)) {
          padding-left: 15px;
        }

        .mat-sort-header-arrow {
          margin-left: 2px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        @include rs-font--primary-light;
        @include rs-px-to-rem(14, 18);
        padding: 20px 0 20px 15px;

        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }

  .mat-sort-header-container {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
