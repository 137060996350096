%rs-button-spinner-type--spinner {
  .spinner-container {
    z-index: 10;
    @include rs-vertical-horizontal-align();

    .mat-mdc-progress-spinner {
      display: none;
    }
  }
}

%rs-button-spinner-type--spinner--spinning {
  .mat-mdc-progress-spinner {
    display: block;
  }

  .spinner-container svg {
    height: auto !important;
  }
}

%rs-button-spinner-type--border--spinning {
  $position: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: $position;
    left: $position;
    right: $position;
    bottom: $position;
    border: 2px solid var(--rs-color--blue--500);
    transition: all 0.5s;
    animation: clipPath 3s infinite linear;
    border-radius: inherit;
  }

  &::after {
    animation: clipPath 3s infinite -1.5s linear;
  }

  @keyframes clipPathRounded {
    0% {
      clip-path: inset(100% 0 0 0);
    }

    50% {
      clip-path: inset(0 0 0 0);
    }

    100% {
      clip-path: inset(0 0 100% 0);
    }
  }

  @keyframes clipPath {
    0%,
    100% {
      clip-path: inset(0 0 98% 0);
    }

    25% {
      clip-path: inset(0 98% 0 0);
    }

    50% {
      clip-path: inset(98% 0 0 0);
    }

    75% {
      clip-path: inset(0 0 0 98%);
    }
  }
}
