// Autocomplete type chips autogrid
.mat-mdc-form-field.mat-mdc-form-field-type-mat-chip-grid {
  .mat-mdc-text-field-wrapper {
    height: auto !important;
  }

  .mat-mdc-autocomplete-trigger {
    margin-left: 0;
  }
}
