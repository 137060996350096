$dot-size: 20px;
$dot-radius: 5px;
$dot-space: 25px;

.loading-dot-animation-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  height: 100vh;
  padding-top: 150px;

  > .dot-animation {
    position: relative;
    width: $dot-size;
    height: $dot-size;
    border-radius: $dot-radius;
    background-color: var(--rs-color--kentucky-bluegrass);
    color: var(--rs-color--kentucky-bluegrass);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &::before {
      left: -$dot-space;
      width: $dot-size;
      height: $dot-size;
      border-radius: $dot-radius;
      background-color: var(--rs-color--kentucky-bluegrass);
      color: var(--rs-color--kentucky-bluegrass);
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      left: $dot-space;
      width: $dot-size;
      height: $dot-size;
      border-radius: $dot-radius;
      background-color: var(--rs-color--kentucky-bluegrass);
      color: var(--rs-color--kentucky-bluegrass);
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }
  }

  .label {
    margin-bottom: 25px;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: var(--rs-color--kentucky-bluegrass);
    transform: rotate(0);
  }

  50%,
  100% {
    background-color: #ebe6ff;
    transform: rotate(360deg);
  }
}
