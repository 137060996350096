@property --rs-card-details--margin-bottom {
  syntax: '<length>';
  inherits: false;
  initial-value: 16px;
}

@property --rs-card-details--padding {
  syntax: '<length>';
  inherits: false;
  initial-value: 16px;
}

@mixin rs-card-details {
  --rs-card-details--font-size: 0.75rem;
  --rs-card-details--line-height: 1.25rem;
  --rs-card-details--padding: 1rem;
  --rs-card-details--margin-bottom: 1rem;

  background-color: var(--rs-ds--background-color);
  border-radius: var(--rs-ds--border-radius--small);
  padding: var(--rs-card-details--padding);
  margin-bottom: var(--rs-card-details--margin-bottom);
  font-size: var(--rs-card-details--font-size);
  line-height: var(--rs-card-details--line-height);
}
