@mixin rs-label {
  @include rs-px-to-rem(14, 20);
  color: var(--rs-ds--text-color);
  @include rs-font--primary-light;
  display: inline-block;
  padding-bottom: 20px;
}

.rs-label {
  @include rs-label;

  &.invalid {
    color: var(--rs-ds--color--alert);
  }
}

.mat-calendar-body-selected {
  color: var(--rs-color--white);
}

button.mat-calendar-body-cell.mat-calendar-body-disabled {
  cursor: not-allowed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.mdc-text-field .mdc-floating-label {
  // Remove weird material mdc blue
  color: rgba(0, 0, 0, 0.6) !important;
}
