[rs-icon-outline],
.material-icons-outlined,
.material-icons.material-icons--outlined {
  font-family: 'Material Icons Outlined';
}

[rs-icon-symbols-outline] {
  font-family: 'Material Symbols Outlined';
}

.material-icons-two-tone,
.material-icons.material-icons--two-tone {
  font-family: 'Material Icons Two Tone';
}

.material-icons-round,
.material-icons.material-icons--round {
  font-family: 'Material Icons Round';
}

.material-icons-sharp,
.material-icons.material-icons--sharp {
  font-family: 'Material Icons Sharp';
}

.mat-icon {
  vertical-align: middle;

  &.green {
    color: var(--rs-color--green--600);
  }

  &.back-arrow {
    transform: rotate(180deg);
  }

  &.yellow {
    color: var(--rs-color--yellow--600) !important;
  }

  &.green {
    color: var(--rs-color--green--600) !important;
  }

  &.red {
    color: var(--rs-ds--color--alert) !important;
  }
}
