@use 'sass:math';
// line-height utility

/** $line-height-map: (
  [class-suffix]: '[line-height in-rem]'
  ex: 16px: 1rem
) */

$line-height-map: ();

@for $i from 1 through 60 {
  $line-height-map: map-merge($line-height-map, (#{$i}px: (math.div($i, 16) + rem)));
}

// @debug $line-height-map;

// Generating font-sizes-map utilities classes
@each $class-suffix, $value in $line-height-map {
  // generate classes
  .rs-line-height-#{$class-suffix} {
    line-height: $value !important;
  }
}
