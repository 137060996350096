$rs-colors-mobile: (
  rs-color--kentucky-bluegrass: #22acc1,
  rs-color--nile-blue: #2e364d,
  rs-color--mischka-grey: #abafb8,
  rs-color--golden-tainoi: #ffc94d,
);

// Generating color utilities classes for mobile
:root {
  @each $color-name, $rs-color in $rs-colors-mobile {
    // generate color variables
    --#{$color-name}: #{$rs-color};

    // generate color classes
    .#{$color-name} {
      color: $rs-color !important;
    }
  }
}
