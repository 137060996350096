@property --rs-card--border-radius {
  syntax: '<length>';
  inherits: true;
  initial-value: 12px;
}

@property --rs-card--padding {
  syntax: '<length>';
  inherits: false;
  initial-value: 30px;
}

@property --rs-card--padding {
  syntax: '<length>';
  inherits: false;
  initial-value: 30px;
}

@property --rs-card--margin-bottom {
  syntax: '<length>';
  inherits: false;
  initial-value: 40px;
}

@property --rs-card--background-color--primary {
  syntax: '<color>';
  inherits: false;
  initial-value: white;
}

@mixin rs-card {
  --rs-card--border-radius: var(--rs-ds--border-radius);
  --rs-card--background-color--primary: var(--rs-color--white);
  --rs-card--box-shadow: var(--rs-ds--box--bottom-shadow);

  background-color: var(--rs-card--background-color--primary);
  border-radius: var(--rs-card--border-radius);
  padding: var(--rs-card--padding);
  box-shadow: var(--rs-card--box-shadow);
  margin-bottom: var(--rs-card--margin-bottom);
}
