@import '../abstract/mixins';
@import 'fonts/rs-font-family';
@import 'fonts/rs-text-utils';
@import 'rs-line-height';
@import 'rs-height';
@import 'rs-min-width';
@import 'rs-colors';
@import 'rs-background-colors';
@import 'rs-spacers';
@import 'rs-animations';

.rs-cursor-pointer {
  cursor: pointer;
}

.rs-uppercase {
  text-transform: uppercase !important;
}

.rs-read-only {
  pointer-events: none;

  .rs-clear-form-field-button {
    display: none !important;
  }
}
