@use 'sass:color';
@use 'rs-mat-buttons.utils';

:root {
  --mdc-fab-small-container-color: var(--rs-ds--color--primary);
}

button[mat-icon-button].mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  padding: 0;

  &.rs-icon-button-orange {
    border: 1px solid;
    line-height: 20px;
    border-color: var(--rs-ds--color--primary);
    color: var(--rs-ds--color--primary);

    &[disabled],
    &.rs-button-disabled {
      border-color: var(--rs-ds--color--icon--disabled);
      color: var(--rs-ds--color--icon--disabled);
    }
  }

  &.rs-button-icon-rectangle {
    border-radius: var(--rs-btn--border-radius);

    .mat-mdc-button-persistent-ripple {
      border-radius: var(--rs-btn--border-radius);
    }

    &:disabled,
    &.rs-button-disabled {
      @include rs-btn--disable-state;
    }
  }

  &:disabled,
  &.rs-button-disabled {
    cursor: not-allowed !important;

    .mat-mdc-button-persistent-ripple::before {
      background-color: transparent;
    }
  }
}

button[mat-button],
a[mat-button] {
  --mat-text-button-with-icon-horizontal-padding: var(--rs-btn--padding-x);
  --mdc-text-button-container-shape: var(--rs-btn--border-radius);

  // Regular buttons
  &.rs-btn--primary {
    @extend %rs-button--primary;
  }

  &.rs-btn--secondary {
    @extend %rs-button--secondary;
  }

  &.rs-btn--text {
    @extend %rs-button--text;
  }

  .mat-mdc-button-persistent-ripple::before {
    background-color: transparent;
  }
}

button {
  &.rs-button-disabled {
    pointer-events: none;
  }
}
