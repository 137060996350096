:root {
  --rs-clear-form-field-btn--button-size: 30px;
  --rs-clear-form-field-btn--icon-size: 18px;
}

mat-form-field {
  &[appearance='outline'] {
    .rs-clear-form-field-button--wrapper {
      margin-left: 5px;
    }
  }

  &.mat-form-field-disabled {
    .rs-clear-form-field-button {
      display: none !important;
    }
  }

  .rs-clear-form-field-button--wrapper {
    width: var(--rs-clear-form-field-btn--button-size);
    transition: 0.4s width ease-out;
    padding: 0;

    .rs-clear-form-field-button.mdc-icon-button {
      padding: 0;
      height: var(--rs-clear-form-field-btn--button-size);
      width: var(--rs-clear-form-field-btn--button-size);
      right: 0;
      transform-origin: center;
      transform: rotate(-180deg);
      transition:
        0.8s transform var(--rs-transition--bezier),
        0.4s right ease-out,
        0.1s height ease-out,
        0.1s width ease-out;

      .mat-icon {
        font-size: var(--rs-clear-form-field-btn--icon-size);
        height: var(--rs-clear-form-field-btn--icon-size);
        line-height: var(--rs-clear-form-field-btn--icon-size);
      }
    }

    &.rs-clear-form-field-button--disabled {
      transition: 0.2s width ease-out;

      .rs-clear-form-field-button {
        pointer-events: none;
        right: -40px;
        transform: rotate(360deg);
        transition:
          0.8s transform var(--rs-transition--bezier),
          1.4s right ease-out,
          0.4s height ease-out,
          0.4s width ease-out;
      }
    }
  }
}

mat-form-field:not(:has(textarea)) {
  .rs-clear-form-field-button--wrapper.rs-clear-form-field-button--disabled {
    width: 0;
    margin-left: 0;
  }
}
