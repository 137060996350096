mat-tab-group {
  .mat-mdc-tab {
    --mat-tab-header-active-label-text-color: var(--rs-ds--color--primary);
    --mat-tab-header-active-focus-label-text-color: var(--rs-ds--color--primary);
    --mat-tab-header-active-hover-label-text-color: var(--rs-ds--color--primary--hover);
    padding: 0 0.5rem;

    // Make selected tab inactive
    &.mdc-tab-indicator--active {
      > * {
        pointer-events: none;
      }
      pointer-events: none;

      .mdc-tab__text-label {
        > * {
          color: var(--rs-ds--color--primary);
        }
      }
    }

    .mdc-tab__text-label {
      column-gap: 0.5rem;
      transition: none;
    }
  }
}
