// To be sure animation of other buttons like download are behind

.mat-mdc-icon-button:has(.mat-badge-content) {
  z-index: 1;
}

.rs-filter-badge-button {
  &.mat-badge-medium.mat-badge-above .mat-badge-content.mat-badge-active {
    color: var(--rs-color--white);
    background-color: var(--rs-color--green--600);
    height: 26px;
    width: 26px;
    font-size: 12px;
    line-height: 25px;
    top: 2px;
    right: 0;
    left: 89%;
  }
}
