.mat-mdc-paginator {
  z-index: 1;
  position: relative;
  box-shadow: 0 -4px 9px 0 rgba(0, 0, 0, 0.1);

  .mat-mdc-form-field {
    width: 50px !important;
  }

  .mdc-notched-outline {
    > * {
      border: none;
      border-bottom: 1px solid;
      border-radius: 0 !important;
    }
  }
}
