@use 'sass:math';
@use '../../../maps/rs-chips' as *;

mat-form-field[appearance='outline'] {
  --mat-form-field-container-height: 50px;
  --mat-form-field-container-vertical-padding: 13px;
  --mat-mdc-form-field-floating-label-scale: 1;

  width: 100%;
  margin-bottom: 10px;

  .mat-mdc-text-field-wrapper {
    padding-right: 16px;
  }

  .mat-mdc-form-field-flex {
    overflow: hidden;
  }
}

/* Utilities */

// #Density (only for mat-select for the moment)
mat-form-field[appearance='outline']:has(mat-select) {
  &[rs-mat-form-field--density='0'] {
    --mat-form-field-container-text-size: 12px;
    --mat-select-trigger-text-size: 12px;
    --mat-form-field-container-height: 24px;
    --mat-form-field-container-vertical-padding: 3px;

    --rs-clear-form-field-btn--button-size: 24px;
    --rs-clear-form-field-btn--icon-size: 16px;
  }
}

// #Colors (only for mat-select for the moment)
mat-form-field[appearance='outline']:has(mat-select) {
  &[rs-mat-form-field--color] {
    --mat-select-trigger-text-font: var(--rs-ds--font--primary);
  }

  @each $color-key, $props in $rs-chips {
    &[rs-mat-form-field--color='#{$color-key}'] {
      @include rs-mat-form-field-colorize($color-key);
    }
  }

  background-color: var(--rs-mat-form-field--bg-color);
  border-radius: var(--mdc-outlined-text-field-container-shape);
}
