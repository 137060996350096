form {
  &[rs-mode='error-relative'] {
    .mat-mdc-form-field-subscript-wrapper {
      &::before {
        display: none;
      }

      .mat-mdc-form-field-error-wrapper {
        position: relative;
        padding-right: 8px;
      }
    }
  }
}
