@import 'variables';
@import 'mixins';
@import 'scss/variables';
@import 'responsive-mixin';
@import 'loading-dots-animation';

#dar-ui-container {
  position: relative;
  height: 100%;
  text-align: center;
  width: 100%;
  overflow: hidden;
  @include rs-px-to-rem(13, 15);
  @include rs-font--primary-light();

  > :nth-child(3),
  > :nth-child(4),
  .swipe-me {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
  }

  h1 {
    @include rs-font--primary-regular();
    @include rs-px-to-rem(22, 22);
    margin-bottom: 20px;
    color: var(--rs-color--nile-blue);
  }

  h2 {
    @include rs-font--primary-regular();
    @include rs-px-to-rem(18, 20);
    margin-bottom: 5px;
    color: var(--rs-color--nile-blue);
  }

  h3 {
    @include rs-font--primary-regular();
    @include rs-px-to-rem(16, 18);
    margin-bottom: 5px;
    color: var(--rs-color--nile-blue);
  }

  p {
    margin-bottom: 8px;
  }

  .align-content-vertical {
    display: flex;
    flex-direction: column;
    padding: 20px $x-gutter 0;
    height: calc(100% - 128px);
    overflow-y: auto;
    margin-top: 58px;
    touch-action: pan-y !important;
    /* Fix swipeLeft with overflow */

    &-center {
      @extend .align-content-vertical;
      justify-content: center;
    }

    &-start {
      @extend .align-content-vertical;
      justify-content: flex-start;
    }
  }

  .comma ~ .comma:not(:empty):before {
    content: ', ';
  }

  .logo {
    margin: 15px auto 30px;
    max-width: 200px;
    max-height: 100px;
  }
}

.dar-fixed-footer {
  position: absolute;
  background-color: var(--rs-ds--background-color);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 10px $x-gutter;

  > button {
    border-radius: 15px !important;
    height: 50px;
    min-width: 50px;

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: var(--rs-color--white) !important;

      .mat-mdc-button-persistent-ripple::before {
        background-color: transparent;
      }
    }

    .mat-icon {
      color: var(--rs-color--white);
    }

    &:not(.dar-back-button) {
      background-color: var(--rs-color--kentucky-bluegrass);
    }

    &.dar-back-button {
      mat-icon {
        transform: rotate(180deg);
      }

      &:not(:disabled) {
        background-color: transparent;
        border: 1px solid;
        border-color: var(--rs-color--kentucky-bluegrass);

        mat-icon {
          color: var(--rs-color--kentucky-bluegrass);
        }
      }
    }
  }
}

button {
  &.rs-clear-form-field-button:hover {
    background-color: var(--rs-color--kentucky-bluegrass) !important;
    color: var(--rs-color--white);
  }

  &.mat-mdc-button.dar-btn-blue {
    background-color: var(--rs-color--kentucky-bluegrass) !important;
    color: var(--rs-color--white);
  }
}

.mat-mdc-icon-button {
  .mat-mdc-button-persistent-ripple {
    border-radius: 15px !important;
  }
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--rs-color--kentucky-bluegrass);
}

.mat-mdc-form-field:not(.mat-form-field-invalid, .mat-form-field-disabled) {
  --mat-select-focused-arrow-color: var(--rs-color--kentucky-bluegrass);
  --mdc-outlined-text-field-focus-outline-color: var(--rs-color--kentucky-bluegrass);
  --mdc-outlined-text-field-outline-color: var(--rs-color--kentucky-bluegrass);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--rs-ds--background-color) inset !important;
}

.mat-mdc-form-field input {
  // fix for iphone (of course!)
  height: 20px !important;
}

/* Tablet Portrait */
@include media-query--tablet-portrait {
  #dar-ui-container {
    .align-content-vertical-start {
      justify-content: center;
    }
  }
}

@include media-query--mobile-landscape {
  #dar-ui-container {
    .align-content-vertical-center {
      justify-content: start;
    }
  }
}

@include media-query--tablet-portrait-landscape {
  #dar-ui-container {
    p {
      line-height: 20px;
    }

    .logo {
      max-height: 200px;
      max-width: 300px;
    }

    font-size: 14px !important;
  }
}
