table.white-box-table {
  table-layout: fixed;
  width: 100%;

  thead {
    tr {
      border-bottom: 1px solid var(--rs-ds--border-color);

      th {
        height: 2rem;
        vertical-align: top;
        @include rs-font--primary-regular;
      }
    }
  }

  tbody {
    tr:first-child {
      height: 3rem;
      vertical-align: bottom;
    }

    td {
      line-height: 2rem;
      @include rs-font--primary-light;
    }
  }
}
