@use '../../abstract/mixins' as rsMixins;

.rs-card,
rs-card {
  // Default rs card
  &:not([card-style]) {
    @include rsMixins.rs-card;
  }

  &[card-style='details'] {
    @include rsMixins.rs-card-details;
  }
}

rs-card {
  display: block;
}

// Structured rs-card
.rs-card,
rs-card {
  &:has(header) {
    --rs-card--padding: 1rem;
  }

  header {
    padding: 0.5rem 0.5rem 1.5rem;
    border-bottom: 1px solid var(--rs-ds--border-color);
  }

  main {
    padding: 1rem 0.5rem 0.5rem;
  }
}
