@use 'sass:color';
@use '../../../abstract/mixins' as rsMixins;

button,
a[mat-button] {
  --rs-btn--border-radius: 12px;
  --rs-btn--padding-inline: 1rem;
  --rs-btn--padding-block: 0.438rem;
  --rs-btn--with-icon--padding: 1rem;
  --rs-btn--height: 2.5rem;
  --rs-btn--line-height: 1rem;
  --rs-btn--color--disabled: hsl(from var(--rs-ds--color--primary) h calc(s + 4) calc(l + 22));
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}

@mixin rs-btn--disable-state {
  pointer-events: all !important;
  cursor: not-allowed !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: var(--rs-color--white) !important;

  .mat-icon {
    color: var(--rs-color--white) !important;
  }

  .mat-mdc-button-persistent-ripple::before {
    background-color: transparent;
  }
}

// Regular buttons
%rs-button {
  min-width: auto;
  background: transparent;
  border-radius: var(--rs-btn--border-radius);
  padding-inline: var(--rs-btn--padding-inline);
  padding-block: var(--rs-btn--padding-block);
  height: var(--rs-btn--height);
  line-height: var(--rs-btn--line-height);
  border: none;
  @include rsMixins.rs-font--primary-regular;

  > mat-icon {
    --mat-text-button-icon-offset: -3px;
  }

  &:focus-visible {
    outline: 2px solid var(--rs-color--blue--500);
    outline-offset: -2px;
  }
}

%rs-button--primary {
  @extend %rs-button;
  background-color: var(--rs-ds--color--primary);
  color: white;

  &:hover {
    background-color: var(--rs-ds--color--primary--hover);
  }

  &:disabled,
  &.mat-mdc-button-disabled,
  &.rs-button-disabled {
    background-color: var(--rs-btn--color--disabled);
  }

  .mat-icon {
    color: white;
  }
}

%rs-button--secondary {
  @extend %rs-button;
  background-color: transparent;
  color: var(--rs-ds--color--primary);
  border: 1px solid var(--rs-ds--color--primary);

  .mat-icon {
    color: var(--rs-ds--color--primary);
  }

  &:hover {
    border: 1px solid var(--rs-ds--color--primary--hover);
    color: var(--rs-ds--color--primary--hover);

    mat-icon {
      color: var(--rs-ds--color--primary--hover);
    }
  }

  &:disabled,
  &.mat-mdc-button-disabled,
  &.rs-button-disabled {
    border: 1px solid var(--rs-btn--color--disabled);
    color: var(--rs-btn--color--disabled);
  }
}

%rs-button--text {
  --rs-btn--with-icon--padding: 0;
  @extend %rs-button;
  min-width: auto;
  padding-inline: 0;
  background-color: transparent !important;
  color: var(--rs-ds--color--primary);

  .mat-icon {
    --mat-text-button-icon-offset: -1px;
    color: var(--rs-ds--color--primary);
  }

  &:hover {
    color: var(--rs-ds--color--primary--hover);

    mat-icon {
      color: var(--rs-ds--color--primary--hover);
    }
  }

  &:disabled,
  &.mat-mdc-button-disabled,
  &.rs-button-disabled {
    color: var(--rs-btn--color--disabled);
  }

  &:hover {
    text-decoration: underline;
  }

  .mat-ripple.mat-mdc-button-ripple {
    display: none;
  }
}
