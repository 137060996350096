.mat-mdc-standard-chip {
  .mdc-evolution-chip__cell--primary {
    width: calc(100% - 36px) !important;
  }

  .mdc-evolution-chip__cell {
    overflow: hidden;

    .mdc-evolution-chip__action--primary,
    .mat-mdc-chip-action-label {
      overflow: hidden;
    }
  }
}
