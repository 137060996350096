@use '@angular/material' as mat;
@use './colors' as color;
@use './typography' as typography;
@use './components-overrides' as components-ovverides;

$rs-light-theme: mat.define-theme(
  (
    color: color.$theme-config-color--light,
    typography: typography.$theme-config-typography,
    density: (
      //scale: -1
    ),
  )
);

@include mat.core();
$mat-border-radius: 0;

:root {
  @include mat.core-theme($rs-light-theme);
  @include mat.all-component-themes($rs-light-theme);

  @include mat.system-level-colors($rs-light-theme, color.$sys-colors-overrides);
  @include mat.system-level-typography($rs-light-theme, typography.$sys-typography-overrides);

  @include components-ovverides.rs-mat-components-overrides();
}
