.rs-expansion-panel.mat-expansion-panel {
  box-shadow: none;

  .mat-expansion-panel-header:not([aria-disabled='true']) {
    padding: 0 2px 0 0;

    &:hover {
      background-color: transparent;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 0 12px 12px;
  }
}
