rs-tag {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 2px 8px;
  border-radius: 40px;
  text-transform: uppercase;
  color: var(--rs-color--white);
  letter-spacing: 1px;
  @include rs-px-to-rem(12, 18);

  &[color='orange'] {
    @extend rs-tag;
    background-image: linear-gradient(to right, #fdab5d, #fc8e5d);
  }
}
