@import '../maps/rs-colors';

// Generating background color utilities classes
@each $color-name, $rs-color in $rs-colors {
  .rs-bg-#{$color-name} {
    background-color: $rs-color !important;
  }
}

.rs-ds--bg-color--primary {
  background-color: var(--rs-ds--color--primary);
}

.rs-ds--bg-color--error {
  background-color: var(--rs-ds--color--alert);
}
