.rs-progress-bar {
  min-width: 50px;
  border-radius: 3px;

  &.upload-successful {
    .mdc-linear-progress__bar-inner {
      border-color: var(--rs-color--green--600) !important;
    }
  }

  &.upload-error {
    .mdc-linear-progress__bar-inner {
      border-color: var(--rs-ds--color--alert) !important;
    }
  }
}
