:root {
  --mdc-radio-selected-focus-icon-color: var(--rs-ds--color--primary--hover);
  --mdc-radio-selected-hover-icon-color: var(--rs-ds--color--primary--hover);
}

.mat-mdc-radio-button {
  .mdc-radio .mdc-radio__background::before {
    // Remove focus ripple
    display: none;
  }
}

// hover state
.mat-mdc-radio-button:hover:not(:active) {
  --mdc-radio-selected-icon-color: var(--rs-ds--color--primary--hover);
  --mat-radio-label-text-color: black;
  cursor: pointer;

  .mdc-label:hover {
    cursor: pointer;
  }

  // Only unchecked
  &:has(input[type='radio']:not(:checked)) {
    .mdc-radio__background .mdc-radio__outer-circle {
      border-color: var(--rs-color--grey--500) !important;
    }
  }
}
