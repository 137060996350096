/* Tablet Portrait and Landscape */
@mixin media-query--tablet-portrait-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* Tablet Portrait */
@mixin media-query--tablet-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

/* Mobile Landscape */
@mixin media-query--mobile-landscape {
  @media only screen and (max-device-height: 450px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}
