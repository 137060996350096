.rs-nav {
  &-vertical {
    @extend .rs-nav;

    a {
      display: table;
    }

    &--sticky-top {
      @extend .rs-nav;
      @extend .rs-nav-vertical;
      position: sticky !important;
      top: 40px;
      align-self: flex-start;
    }
  }

  a {
    cursor: pointer;
    color: var(--rs-ds--text-color);
    @include rs-font--primary-light;
    @include rs-px-to-rem(14, 18);
    margin-bottom: 20px;

    &.selected,
    &:hover {
      color: var(--rs-ds--color--primary) !important;
    }
  }
}
