.mat-mdc-form-field {
  &[rs-input-prefix] {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix .mat-mdc-floating-label {
        left: -18px;
      }

      .mat-mdc-form-field-icon-prefix {
        align-self: baseline;
      }
    }

    .rs-input-prefix {
      opacity: 0;
      @include rs-font--primary-regular();
      color: var(--rs-color--grey--700);
      transform: translateY(1px);
      display: inline-block;
    }

    &:not(.mat-form-field-hide-placeholder) {
      .rs-input-prefix {
        transition: opacity ease-in-out 0.3s;
        opacity: 1;
      }
    }
  }
}
