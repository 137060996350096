@import '../maps/rs-colors';

// Generating color utilities classes
:root {
  @each $color-name, $rs-color in $rs-colors {
    // generate color variables
    --#{$color-name}: #{$rs-color};

    // generate color classes
    .#{$color-name} {
      color: $rs-color !important;
    }
  }

  .rs-ds--color--primary {
    color: var(--rs-ds--color--primary);
  }

  .rs-ds--color--error {
    color: var(--rs-ds--color--alert);
  }
}
