.mat-accordion {
  .mat-expansion-panel {
    margin-bottom: 10px;

    &:not([class*='mat-elevation-z']) {
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.05);
    }

    .mat-expansion-panel-header {
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.05);
    }

    .mat-expansion-panel-body {
      background-color: var(--rs-ds--background-color);
      padding: 30px 24px;
    }
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-content {
    font-size: 14px;
    line-height: 18px;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }
}

.mat-expansion-panel {
  --mat-expansion-container-shape: var(--rs-card--border-radius);
  border-radius: var(--rs-card--border-radius);

  &[rs-expansion-panel-style='flat'] {
    --mat-expansion-container-background-color: none;
    --mat-expansion-header-hover-state-layer-color: none;
    --mat-expansion-header-expanded-state-height: var(--mat-expansion-header-collapsed-state-height);

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    .mat-expansion-panel-header {
      padding: 0;
    }

    .mat-expansion-panel-body {
      margin: 0;
      padding: 0;
    }
  }
}
