@use 'sass:math';

/** Creating some spacing utilities like boostrap but with rem/px
  * @usage: ms-16px >> margin-left: 1rem !important
  */
$rs-spacers: (
  'margin': (
    property: margin,
    class: m,
  ),
  'margin-start': (
    property: margin-left,
    class: ms,
  ),
  'margin-end': (
    property: margin-right,
    class: me,
  ),
  'margin-top': (
    property: margin-top,
    class: mt,
  ),
  'margin-bottom': (
    property: margin-bottom,
    class: mb,
  ),
  'margin-y': (
    property: (
      margin-top,
      margin-bottom,
    ),
    class: my,
  ),
  'margin-x': (
    property: (
      margin-left,
      margin-right,
    ),
    class: mx,
  ),
  'padding': (
    property: padding,
    class: p,
  ),
  'padding-start': (
    property: padding-left,
    class: ps,
  ),
  'padding-end': (
    property: padding-right,
    class: pe,
  ),
  'padding-top': (
    property: padding-top,
    class: pt,
  ),
  'padding-bottom': (
    property: padding-bottom,
    class: pb,
  ),
  'padding-y': (
    property: (
      padding-top,
      padding-bottom,
    ),
    class: py,
  ),
  'padding-x': (
    property: (
      padding-left,
      padding-right,
    ),
    class: px,
  ),
);

@for $i from -60 through 60 {
  @each $key, $value in $rs-spacers {
    $property: map-get($value, property);
    $class: map-get($value, class);

    @if type-of($property) ==list {
      @each $prop in $property {
        .#{$class}-#{$i}px {
          #{$prop}: unquote('#{math.div($i, 16)}rem !important');
        }
      }
    } @else {
      .#{$class}-#{$i}px {
        #{$property}: unquote('#{math.div($i, 16)}rem !important');
      }
    }
  }
}
