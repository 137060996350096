@use 'sass:math';
// height utility

/** $height-map: (
  [class-suffix]: '[height in-rem]'
  ex: 16px: 1rem
) */

$height-map: ();

@for $i from 1 through 120 {
  $height-map: map-merge($height-map, (#{$i}px: (#{math.div($i, 16)}rem)));
}

// @debug $height-map;

// Generating font-sizes-map utilities classes
@each $class-suffix, $value in $height-map {
  // generate classes
  .rs-height-#{$class-suffix} {
    height: $value !important;
  }
}
