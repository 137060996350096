/* import only the necessary Bootstrap files */
@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';

/* Vars */
$font-family-sans-serif:
  'Montserrat Light',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  'Noto Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji';
$font-family-base: $font-family-sans-serif;

$grid-gutter-width: 30px;

$spacer: 1rem;
$spacers: ();

$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

/** increase the width of the grid lg and xl breakpoints */
$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

/** increase container width for the wider lg and xl breakpoints */
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 90%,
  xxl: 80%,
);

/* --- end customization --- */

//@import '../../../../../node_modules/bootstrap/scss/bootstrap';

// scss-docs-start import-stack
// Configuration
//@import "../../../../../node_modules/bootstrap/scss/functions";
//@import "../../../../../node_modules/bootstrap/scss/variables";
@import '../../../../../node_modules/bootstrap/scss/maps';
@import '../../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../../../../../node_modules/bootstrap/scss/root';
@import '../../../../../node_modules/bootstrap/scss/reboot';
@import '../../../../../node_modules/bootstrap/scss/containers';
@import '../../../../../node_modules/bootstrap/scss/grid';
@import '../../../../../node_modules/bootstrap/scss/card';
//@import "../../../../../node_modules/bootstrap/scss/type";
//@import "../../../../../node_modules/bootstrap/scss/images";
//@import "../../../../../node_modules/bootstrap/scss/tables";
//@import "../../../../../node_modules/bootstrap/scss/forms";
//@import "../../../../../node_modules/bootstrap/scss/buttons";
//@import "../../../../../node_modules/bootstrap/scss/transitions";
//@import "../../../../../node_modules/bootstrap/scss/dropdown";
//@import "../../../../../node_modules/bootstrap/scss/button-group";
//@import "../../../../../node_modules/bootstrap/scss/nav";
//@import "../../../../../node_modules/bootstrap/scss/navbar";
//@import "../../../../../node_modules/bootstrap/scss/accordion";
//@import "../../../../../node_modules/bootstrap/scss/breadcrumb";
//@import "../../../../../node_modules/bootstrap/scss/pagination";
//@import "../../../../../node_modules/bootstrap/scss/badge";
//@import "../../../../../node_modules/bootstrap/scss/alert";
//@import "../../../../../node_modules/bootstrap/scss/progress";
//@import "../../../../../node_modules/bootstrap/scss/list-group";
//@import "../../../../../node_modules/bootstrap/scss/close";
//@import "../../../../../node_modules/bootstrap/scss/toasts";
//@import "../../../../../node_modules/bootstrap/scss/modal";
//@import "../../../../../node_modules/bootstrap/scss/tooltip";
//@import "../../../../../node_modules/bootstrap/scss/popover";
//@import "../../../../../node_modules/bootstrap/scss/carousel";
//@import "../../../../../node_modules/bootstrap/scss/spinners";
//@import "../../../../../node_modules/bootstrap/scss/offcanvas";
//@import "../../../../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import '../../../../../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../../../../../node_modules/bootstrap/scss/utilities/api';
// scss-docs-end import-stack
