// Rs fonts
.rs-font--primary-light,
rs-font-light,
[rs-font='light'] {
  @include rs-font--primary-light();
}

.rs-font--primary-regular,
rs-font-regular,
[rs-font='regular'] {
  @include rs-font--primary-regular();
}

.rs-font--primary-medium,
rs-font-medium,
[rs-font='medium'] {
  @include rs-font--primary-medium();
}

.rs-font--primary-bold,
[rs-font='bold'] {
  @include rs-font--primary-bold();
}
