.mat-mdc-snack-bar-container {
  max-width: 50vw !important;
  padding: 20px;

  &.success {
    background: var(--rs-color--green--600);
    @include rs-font--primary-light;

    .md-simple-snackbar-message {
      color: var(--rs-color--white);
    }
  }

  &.error {
    background: var(--rs-ds--color--alert);
    @include rs-font--primary-light;

    .md-simple-snackbar-message {
      color: var(--rs-color--white);
    }
  }
}
