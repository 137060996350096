@import 'rs-spinner-button.utils';

.rs-button-spinner {
  &.rs-button-spinning {
    pointer-events: none;
  }

  &.rs-button-spinner-type--spinner {
    @extend %rs-button-spinner-type--spinner;

    &.rs-button-spinning {
      @extend %rs-button-spinner-type--spinner--spinning;
    }
  }

  &.rs-button-spinner-type--border {
    &.rs-button-spinning {
      @extend %rs-button-spinner-type--border--spinning;
    }
  }
}
