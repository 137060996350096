@import '../../../../../node_modules/bootstrap/scss/variables';

:root {
  interpolate-size: allow-keywords;

  @media (width >=$xl) {
    --rs-ds--main-container--width: 90%;
  }

  @media (width >=$xxl) {
    --rs-ds--main-container--width: 80%;
  }
}

html {
  height: 100%;
  font-size: 16px;

  &.stop-scroll {
    body {
      overflow-y: hidden;
    }
  }
}

body {
  background-color: var(--rs-ds--background-color);
  font-size: var(--rs-ds--body-font-size);
  line-height: var(--rs-ds--body-line-height);
  @include rs-font--primary-regular;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;

  &.no-scroll,
  &[data-no-scroll='on'] {
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    position: fixed;
  }

  color: var(--rs-ds--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include rs-font--primary-medium;
  margin: 0;
  text-wrap: balance;
}

h1 {
  @include rs-px-to-rem(20, 28);
}

h2 {
  @include rs-px-to-rem(18, 26);
}

h3 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h4 {
  @include rs-px-to-rem(15, 19);
}

h5 {
  @include rs-px-to-rem(13, 16);
}

h6 {
  @include rs-px-to-rem(11, 14);
}

p {
  margin-bottom: 5px;
  text-wrap: pretty;
}

.cdk-global-scrollblock {
  overflow-y: initial;
}

a {
  color: var(--rs-ds--link--color);
  text-decoration: none;

  &:hover {
    color: hsl(from var(--rs-ds--link--color) h calc(s - 2) calc(l - 15));
    text-decoration: underline;
  }

  &:not([href]) {
    cursor: pointer;
  }
}

rs-container {
  display: block;
  width: var(--rs-ds--main-container--width);
  padding-inline: var(--rs-ds--main-container--padding-inline);
  margin-inline: auto !important; // >> Allow to apply bootstrap row utility class to container sparing an extra div

  &[rs-container-width='full'] {
    width: calc(100% - (var(--rs-ds--main-container--padding-inline) * 2));
    padding-inline: calc((100% - var(--rs-ds--main-container--width)) / 2);
  }

  &[rs-two-col-grid] {
    display: grid;
    grid-template-columns: 33fr 66fr;
    gap: 32px;

    @media (width <=$lg) {
      grid-template-columns: 1fr;
    }
  }
}

.ellipsis {
  @include rs-ellipsis();
}

@font-face {
  font-family: Montserrat-Light;
  src: url('../../assets/fonts/Montserrat-Light.ttf');
  font-weight: 400;
}

.container {
  padding: 0 1rem;
  margin: auto; // >> Allow to apply bootstrap row utility class to container sparing an extra div
}

.topbar-container .topbar > .topbar-row {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
