@mixin rs-mat-components-overrides() {
  --mat-menu-item-label-text-weight: 400;
  //.mat-datepicker-content

  // --mat-datepicker
  --mat-datepicker-calendar-container-shape: var(--rs-ds--border-radius);
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  // --mat-sidenav--
  --mat-sidenav-container-shape: 0;

  //--mdc-switch-
  --mdc-switch-track-height: 24px;
  --mat-switch-with-icon-handle-size: 16px;
  --mat-switch-pressed-handle-size: 18px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 3px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 19px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 18px;
  --mdc-switch-track-width: 38px;

  // --mat-table
  --mat-table-row-item-outline-color: var(--rs-ds--border-color);

  --mat-option-hover-state-layer-color: var(--rs-ds--color--hover);

  // --mat-dialog
  --mat-dialog-container-max-width: 1000px;
}
