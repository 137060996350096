@use 'sass:math';
// min-width utility

/** $min-width-map: (
  [class-suffix]: '[min-width in-rem]'
  ex: 16px: 1rem
) */

$min-width-map: ();

@for $i from 1 through 120 {
  $min-width-map: map-merge($min-width-map, (#{$i}px: (#{math.div($i, 16)}rem)));
}

// @debug $min-width-map;

// Generating font-sizes-map utilities classes
@each $class-suffix, $value in $min-width-map {
  // generate classes
  .rs-min-width-#{$class-suffix} {
    min-width: $value !important;
  }
}
