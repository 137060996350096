.mat-button-toggle-group {
  &.mat-button-toggle-group-appearance-standard {
    --mat-standard-button-toggle-text-color: var(--rs-ds--text-secondary);
    --mat-standard-button-toggle-label-text-size: var(--rs-ds--body-font-size);
    --mat-standard-button-toggle-shape: var(--rs-ds--border-radius);
    --mat-standard-button-toggle-background-color: transparent;
    --mat-standard-button-toggle-height: var(--rs-ds--button-height);
    --mat-standard-button-toggle-state-layer-color: var(--rs-ds--color--hover);
    --mat-standard-button-toggle-hover-state-layer-opacity: 1;
    --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;

    --mat-standard-button-toggle-selected-state-text-color: var(----rs-ds--text-color);
    --mat-standard-button-toggle-selected-state-background-color: var(--rs-ds--border-color);

    --mat-standard-button-toggle-disabled-state-text-color: var(--rs-ds--text-disabled);
    --mat-standard-button-toggle-disabled-state-background-color: transparent;

    --mat-standard-button-toggle-disabled-selected-state-text-color: var(--rs-ds--text-disabled);
    --mat-standard-button-toggle-disabled-selected-state-background-color: var(--rs-ds--border-color);

    --mat-standard-button-toggle-divider-color: var(--rs-ds--text-secondary);

    &[disabled] {
      --mat-standard-button-toggle-divider-color: var(--rs-ds--text-disabled);
    }

    &:hover {
      color: var(----rs-ds--text-color);
    }

    .mat-button-toggle {
      &:not(.mat-button-toggle-disabled) {
        &:hover {
          color: var(----rs-ds--text-color);

          .mat-button-toggle-focus-overlay {
            background-color: var(--mat-standard-button-toggle-selected-state-background-color);
          }
        }
      }

      button {
        z-index: 1;
      }

      .mat-ripple {
        z-index: 0;
      }
    }
  }
}
