@use '../../../abstract/variables';
@use '@angular/material' as mat;

:root {
  --mdc-checkbox-selected-focus-icon-color: var(--rs-ds--color--primary--hover);
  --mdc-checkbox-selected-hover-icon-color: var(--rs-ds--color--primary--hover);
}

.mat-mdc-checkbox {
  .mdc-checkbox__ripple {
    // Remove staying focused state ripple after click
    display: none !important;
  }

  &.rs-mat-checkbox-align-items-start.mat-accent {
    .mdc-form-field {
      align-items: flex-start;

      .mdc-checkbox {
        top: 0;
      }
    }
  }
}

// hover state
.mat-mdc-checkbox:hover:not(:active) {
  --mat-checkbox-label-text-color: black;
  --mdc-checkbox-selected-icon-color: var(--rs-ds--color--primary--hover);

  // Only unchecked
  &:has(input[type='checkbox']:not(:checked)) {
    --mdc-checkbox-unselected-icon-color: var(--rs-color--grey--500);

    .mdc-checkbox__background {
      border-color: var(--rs-color--grey--500) !important;
    }
  }
}
