@charset "UTF-8";
@use 'sass:math';
@import 'mixins/rs-cards/rs-card';
@import 'mixins/rs-cards/rs-card--details';
@import 'mixins/rs-chip';
@import 'mixins/rs-mat-form-field--colorize';
@import 'variables';

@mixin rs-vertical-align($position: absolute) {
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  position: $position;
}

@mixin rs-horizontal-align() {
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  position: absolute;
}

@mixin rs-vertical-horizontal-align() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  position: absolute;
}

@mixin rs-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

@mixin rs-mat-icon($icon-name) {
  content: $icon-name;
  font-family: 'Material Icons';
}

@mixin rs-mat-icon-pseudo() {
  content: attr(data-icon);
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
@mixin rs-px-to-rem($pxValue, $line-height: null, $standard-size: 16) {
  font-size: math.div($pxValue, $standard-size) + rem !important;

  @if ($line-height) {
    line-height: math.div($line-height, $standard-size) + rem !important;
  }
}

@mixin rs-set-mat-icon-size($size) {
  font-size: $size;
  line-height: $size;
  height: $size;
  width: $size;
}

/* Fonts */
@mixin rs-font--primary-light {
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
}

@mixin rs-font--primary-regular {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
}

@mixin rs-font--primary-medium {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 600;
}

@mixin rs-font--primary-bold {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 800;
}

@mixin rs-debug-layout {
  *,
  *:after,
  *:before {
    outline: 1px solid lime !important;
  }
}
